import React from "react";
import * as styles from "../../../styles/careers/intern.module.scss";
import { CareerMidLayout } from "../../../organisms/careers/CareerMidLayout";
import { MidEntryHeader } from "../../../organisms/menus/MidEntryHeader";
import { Link } from "gatsby";
import Scroll, { scrollImageColorTypes } from "../../../atoms/newScroll";
import { LinkMargin } from "../../../atoms/LinkMargin";
import { SEO } from "../../../atoms/seo/SEO";
import seoImage from "../../../images/career_mid/career_mid_head.jpg";

export default ({ location: { pathname } }) => (
  <CareerMidLayout current="entry" extraHeaders={<MidEntryHeader />}>
    <SEO
      pathname={pathname}
      title="中途採用TOP | MidCareerTop"
      description="スプリーブホールディングス株式会社のキャリア採用枠へはこちらからエントリーいただけます。社会、顧客、従業員に対して、「この上ない最高」を提供する会社を共に目指し、人と人の繋がりを強めて力強く事業を推進していく仲間を募集しています。"
      image={seoImage}
    />
    <div className={styles.entryTitle}>
      <h2>Entry</h2>
      <h3>キャリア採用エントリー</h3>
      <Scroll type={scrollImageColorTypes.BLACK} />
    </div>

    <div className={styles.entry}>
      <a id="Entry" />
      <LinkMargin />
      <h4>現在募集している職種はございません。</h4>
    </div>
  </CareerMidLayout>
);

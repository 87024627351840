import React from "react";
import {AnchorHeaders} from "./AnchorHeaders";
import * as styles from './menus.module.scss';

const links = [
    {
        id: "Entry",
        label: "LINEエントリー",
    },

];



export const MidEntryHeader = () => <AnchorHeaders links={ links } spContainerClassName={styles.midAnchorSpMenu}/>